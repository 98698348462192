import * as React from 'react'
  /* @jsx mdx */
import { mdx } from '@mdx-js/react';
/* @jsxRuntime classic */

/* @jsx mdx */

import DefaultLayout from "/vercel/path0/src/templates/MDXDocs.js";
export const _frontmatter = {};
const layoutProps = {
  _frontmatter
};
const MDXLayout = DefaultLayout;
export default function MDXContent({
  components,
  ...props
}) {
  return <MDXLayout {...layoutProps} {...props} components={components} mdxType="MDXLayout">


    <h2 {...{
      "id": "using-your-own-submit-button",
      "style": {
        "position": "relative"
      }
    }}><a parentName="h2" {...{
        "href": "#using-your-own-submit-button",
        "aria-label": "using your own submit button permalink",
        "className": "anchor before"
      }}><svg parentName="a" {...{
          "aria-hidden": "true",
          "focusable": "false",
          "height": "16",
          "version": "1.1",
          "viewBox": "0 0 16 16",
          "width": "16"
        }}><path parentName="svg" {...{
            "fillRule": "evenodd",
            "d": "M4 9h1v1H4c-1.5 0-3-1.69-3-3.5S2.55 3 4 3h4c1.45 0 3 1.69 3 3.5 0 1.41-.91 2.72-2 3.25V8.59c.58-.45 1-1.27 1-2.09C10 5.22 8.98 4 8 4H4c-.98 0-2 1.22-2 2.5S3 9 4 9zm9-3h-1v1h1c1 0 2 1.22 2 2.5S13.98 12 13 12H9c-.98 0-2-1.22-2-2.5 0-.83.42-1.64 1-2.09V6.25c-1.09.53-2 1.84-2 3.25C6 11.31 7.55 13 9 13h4c1.45 0 3-1.69 3-3.5S14.5 6 13 6z"
          }}></path></svg></a>{`Using your own submit button`}</h2>
    <p>{`By default, the SDK will render a submit button itself inside the frame. However if you wish to programmatically submit the Payment Authority form yourself (using your own submit button), below are instructions to do so.`}</p>
    <p>{`In your `}<strong parentName="p">{`JavaScript`}</strong>{`:`}</p>
    <pre><code parentName="pre" {...{
        "className": "language-jsx"
      }}>{`import sdk from '@medipass/partner-sdk';

// 1. Set up an instance variable.
let submitMedipassForm;

sdk.renderAuthorizePayment(
  {},
  {
    showSubmitButton: false,
    onFormStateChanged: form => {
      // 2. Assign the submitForm function to the instance variable.
      submitMedipassForm = form.submitForm;
    },
    onSuccess: async data => {
      // 4a. If the payment authorization process is successful, the onSuccess callback will be invoked.
      console.log('Success!');
    },
    onError: err => {
      // 4b. If the payment authorization process has errored, the onError callback will be invoked.
      console.log('Error!');
    }
  }
);

function submitMyForm() {
  // 3. Submit the Medipass form when the user clicks your submit button
  submitMedipassForm();
}
`}</code></pre>
    <p>{`In your `}<strong parentName="p">{`HTML`}</strong>{`:`}</p>
    <pre><code parentName="pre" {...{
        "className": "language-jsx"
      }}>{`<div id="authorize-payment"></div>
<button onClick={submitMyForm}>Submit</button>
`}</code></pre>
    <h4 {...{
      "id": "example-using-react",
      "style": {
        "position": "relative"
      }
    }}><a parentName="h4" {...{
        "href": "#example-using-react",
        "aria-label": "example using react permalink",
        "className": "anchor before"
      }}><svg parentName="a" {...{
          "aria-hidden": "true",
          "focusable": "false",
          "height": "16",
          "version": "1.1",
          "viewBox": "0 0 16 16",
          "width": "16"
        }}><path parentName="svg" {...{
            "fillRule": "evenodd",
            "d": "M4 9h1v1H4c-1.5 0-3-1.69-3-3.5S2.55 3 4 3h4c1.45 0 3 1.69 3 3.5 0 1.41-.91 2.72-2 3.25V8.59c.58-.45 1-1.27 1-2.09C10 5.22 8.98 4 8 4H4c-.98 0-2 1.22-2 2.5S3 9 4 9zm9-3h-1v1h1c1 0 2 1.22 2 2.5S13.98 12 13 12H9c-.98 0-2-1.22-2-2.5 0-.83.42-1.64 1-2.09V6.25c-1.09.53-2 1.84-2 3.25C6 11.31 7.55 13 9 13h4c1.45 0 3-1.69 3-3.5S14.5 6 13 6z"
          }}></path></svg></a>{`Example using React`}</h4>
    <pre><code parentName="pre" {...{
        "className": "language-jsx"
      }}>{`import React from 'react';
import sdk from '@medipass/partner-sdk';

sdk.setConfig({
  env: 'stg',
  appId: 'your-app-id',
  appVersion: '1.0.0'
});

let submitForm;

export default function MyForm() {
  const [showSubmitButton, setShowSubmitButton] = React.useState(false);

  React.useEffect(() => {
    sdk.renderAuthorizePayment(
      {},
      {
        token: 'your-token',
        containerId: 'authorize-payment',
        width: '400px',
        showSubmitButton: false,

        onScreenStateChanged: screen => {
          setShowSubmitButton(screen === 'payment-capture');
        },  
        onFormStateChanged: form => {
          submitForm = form.submitForm;
        },
        onSuccess: data => {
          console.log('Success!', JSON.stringify(data));
        },
        onError: err => {
          console.log('Error!', JSON.stringify(err));
        }
      }
    )
  }, []);

  function handleSubmit() {
    submitForm && submitForm();
  }

  return (
    <div>
      <div id="authorize-payment" />
      {
        showSubmitButton && 
          <button onClick={handleSubmit}>submit</button>
      }
    </div>
  )
}
`}</code></pre>

    </MDXLayout>;
}
;
MDXContent.isMDXComponent = true;
      