import React from 'react';
import * as fannypack from 'fannypack';
import { MDXProvider } from '@mdx-js/react';
import _get from 'lodash/get';

import Layout from '../components/Layout';
import SEO from '../components/SEO';
import useNode from '../useNode';

const components = {
  ...fannypack,
  a: props => <fannypack.Link {...props} />,
  blockquote: props => (
    <fannypack.Blockquote
      backgroundColor="primaryTint"
      borderColor="primary"
      marginTop="major-2"
      marginBottom="major-2"
      {...props}
    />
  ),
  h1: props => <fannypack.Heading marginBottom="major-4" {...props} />,
  h2: props => <fannypack.Heading use="h2" marginTop="major-6" {...props} />,
  h3: props => <fannypack.Heading use="h3" marginTop="major-6" {...props} />,
  h4: props => <fannypack.Heading use="h4" marginTop="major-4" {...props} />,
  h5: props => <fannypack.Heading use="h5" marginTop="major-4" {...props} />,
  h6: props => <fannypack.Heading use="h6" marginTop="major-4" {...props} />,
  hr: props => <fannypack.Divider {...props} />,
  p: props => <fannypack.Paragraph {...props} />,
  strong: props => <fannypack.Text fontWeight="semibold" {...props} />,
  em: props => <fannypack.Text use="em" {...props} />,
  ul: props => <fannypack.List listStyleType="disc" listStylePosition="outside" marginLeft="major-4" {...props} />,
  ol: props => <fannypack.List isOrdered listStylePosition="inside" {...props} />,
  li: props => <fannypack.List.Item {...props} />,
  code: props => <fannypack.Code {...props} />,
  table: props => <fannypack.Table isFullWidth isStriped {...props} />,
  tbody: props => <fannypack.Table.Body {...props} />,
  thead: props => <fannypack.Table.Head {...props} />,
  tfoot: props => <fannypack.Table.Foot {...props} />,
  tr: props => <fannypack.Table.Row {...props} />,
  td: props => <fannypack.Table.Cell {...props} />,
  th: props => <fannypack.Table.HeadCell {...props} />,
  img: props => <fannypack.Image {...props} {...props} />,
  pre: props => {
    const lang = _get(props, 'children.props.className', '').split('-')[1];
    return (
      <fannypack.HighlightedCode {...props} isBlock marginBottom="major-4" lang={lang}>
        {_get(props, 'children.props.children', '').replace(/\n$/, '')}
      </fannypack.HighlightedCode>
    );
  }
};

const Docs = ({ children }) => {
  const node = useNode();
  const title = _get(node, 'frontmatter.title');
  return (
    <Layout>
      <SEO title={title} />
      <MDXProvider components={components}>{children}</MDXProvider>
    </Layout>
  );
};

export default Docs;
